<template>
    <div class="item">
      <div class="img">
        <img :src="imagePath" alt="">
      </div>
      <div class="font">
        <div class="one overflow">{{title}}</div>
        <div class="two overflow">{{summary}}</div>
      </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props:{
      title:{
        type:String,
      },
      summary:{
        type:String
      },
      imagePath:{
        type:String
      }
    },
    methods: {},
    components: {}
  }
</script>

<style lang="less" scoped>
  .item {

    cursor: pointer;
    //margin-right: 16px;
    .img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .font {
      margin-top: 8px;
      color: #000;
      .one  {
        font-size: 16px;
      }
      .two {
        margin-top: 8px;
        font-size: 12px;
        color: rgba(0, 0, 0 , .6);
      }

    }
  }

</style>
