<template>
  <div class="content">
    <div class="middle">
      <div class="nav">
        <div class="item" v-for="(item, index) in navList" :class="[currentIndex === index ? 'bgw' :'']"
             @click="navItem(index, item.id)">{{item.name}}
        </div>
      </div>
      <div class="con">
        <div class="more"><span @click="softMore">更多>></span></div>
        <!--<el-row :gutter="10" class="con_content" v-if="dataList.length">
          <el-col :xs="10" :sm="10" :md="6" :lg="1" :xl="1" v-for="item in speakDataList" :key="item.id">
            <div class="item" v-for="item in dataList" @click="$router.push(`/detail/${item.id}`)">
              <img class="img" :src='item.logoUrl' alt="">
              <span>{{item.name}}</span>
            </div>
          </el-col>
        </el-row>-->
        <div class="con_content" v-if="dataList.length">
          <div class="item" v-for="item in dataList" @click="itemClick(item.id)">
            <img class="img" :src='item.logoUrl' alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="noneData" v-else>{{alterTitle}}</div>
<!--     我在这   -->
        <div class="con_content" v-if="dataList1.length&&isShow">
          <div class="item" v-for="item in dataList1" @click="itemClick(item.id)">
            <img class="img" :src='item.logoUrl' alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="con_content" v-if="dataList2.length&&isShow">
          <div class="item" v-for="item in dataList2" @click="itemClick(item.id)">
            <img class="img" :src='item.logoUrl' alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="title">
        <div class="l">
          热门帖子
        </div>
        <div class="r" @click="speakMore">更多>></div>
      </div>
      <div v-if="speakDataList.length>0">
        <el-row :gutter="2" class="con">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in speakDataList" :key="item.id">
            <ArticleFirstListItem :title="item.title" :article_id="item.id" :createTime="item.createTime" :summary="item.summary"
                                  :catePageIndex="currentIndex" @click.native="$router.push(`/detailPost/${item.id}`)"/>
          </el-col>
        </el-row>
      </div>
      <div v-else class="con">
        {{alterTitle}}
      </div>
      <div v-if="speakDataList1.length>0&&speakDataList.length==2">
        <el-row :gutter="2" class="con">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in speakDataList1" :key="item.id">
            <ArticleFirstListItem :title="item.title" :article_id="item.id" :createTime="item.createTime" :summary="item.summary"
                                  :catePageIndex="currentIndex" @click.native="$router.push(`/detailPost/${item.id}`)"/>
          </el-col>
        </el-row>
      </div>
      <div v-if="speakDataList2.length>0&&speakDataList1.length==2">
        <el-row :gutter="2" class="con">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in speakDataList2" :key="item.id">
            <ArticleFirstListItem :title="item.title" :article_id="item.id" :createTime="item.createTime" :summary="item.summary"
                                  :catePageIndex="currentIndex" @click.native="$router.push(`/detailPost/${item.id}`)"/>
          </el-col>
        </el-row>
      </div>
      <div v-if="speakDataList3.length>0&&speakDataList2.length==2">
        <el-row :gutter="2" class="con">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in speakDataList3" :key="item.id">
            <ArticleFirstListItem :title="item.title" :article_id="item.id" :createTime="item.createTime" :summary="item.summary"
                                  :catePageIndex="currentIndex" @click.native="$router.push(`/detailPost/${item.id}`)"/>
          </el-col>
        </el-row>
      </div>
      <div v-if="speakDataList4.length>0&&speakDataList3.length==2">
        <el-row :gutter="2" class="con">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in speakDataList4" :key="item.id">
            <ArticleFirstListItem :title="item.title" :article_id="item.id" :createTime="item.createTime" :summary="item.summary"
                                  :catePageIndex="currentIndex" @click.native="$router.push(`/detailPost/${item.id}`)"/>
          </el-col>
        </el-row>
      </div>
      <div v-if="speakDataList5.length>0&&speakDataList4.length==2">
        <el-row :gutter="2" class="con">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in speakDataList5" :key="item.id">
            <ArticleFirstListItem :title="item.title" :article_id="item.id" :createTime="item.createTime" :summary="item.summary"
                                  :catePageIndex="currentIndex" @click.native="$router.push(`/detailPost/${item.id}`)"/>
          </el-col>
        </el-row>
      </div>
      <div v-if="speakDataList6.length>0&&speakDataList5.length==2">
        <el-row :gutter="2" class="con">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" v-for="item in speakDataList6" :key="item.id">
            <ArticleFirstListItem :title="item.title" :article_id="item.id" :createTime="item.createTime" :summary="item.summary"
                                  :catePageIndex="currentIndex" @click.native="$router.push(`/detailPost/${item.id}`)"/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>

</template>

<script>
  import SpeakItem from "@/components/item/SpeakItem"
  import ArticleFirstListItem from "@/components/item/ArticleFirstListItem"
  import {getNavList, getCateSoft, getHotSpeak, getNewSoft} from '@/utils/network'

  export default {
    name: 'First',
    data() {
      return {
        currentIndex: this.$store.state.isLastPage?parseInt(this.$store.state.catePageIndex):0,
        navList: [],  //nav列表
        cateId: 10,     //分类id
        total: null,   //数据总数
        dataList: [],  //数据列表
        dataList1: [], //也是数据列表
        dataList2: [],
        speakDataList: [],  //热门帖子列表
        speakDataList1: [],
        speakDataList2: [],
        speakDataList4: [],
        speakDataList3: [],
        speakDataList5: [],
        speakDataList6: [],
        alterTitle: '加载中...',  //提示文字
        //最新上架轮播数据
        newDataList: [],
        isShow:true
      }
    },
    created() {
      this.getNavList()
      this.getData()
      this.getSpeakData()
      this.getNewSoftData()
      this.$store.dispatch('isLastPage', {
        isLastPage: false
      })
    },
    beforeDestroy() {

    },
    methods: {
      itemClick(id){
        this.$store.dispatch('catePageIndex', {
          catePageIndex: this.currentIndex
        })
        this.$router.push(`/detail/${id}`)
      },
      async getNewSoftData() {
        const {data: res} = await getNewSoft({
          page: 1,
          per_page: 5,
          sourceType:'web'
        })
        if (res.code !== 200) return  //this.$message.error('请求失败')
        this.newDataList = res.data.vodata
      },
      async getData() {
        this.alterTitle = '加载中...'
        if(this.$store.state.isLastPage){
          var cate = parseInt(this.$store.state.catePageIndex)
          const {data: res} = await getNavList({})
          if (res.code !== 200) return this.$message.error('请求失败')
          this.navList = res.data
          this.cateId = (this.navList)[cate].id
        }
        const {data: res} = await getCateSoft({
          id: this.cateId,
          page: 1,
          per_page: 24,
          sourceType:'web'
        })
        if (res.code !== 200) return this.$message.error('请求失败')
        this.dataList = res.data.vodata.splice(0, 8)
        this.alterTitle = '暂无数据'
        this.dataList1 = res.data.vodata.splice(0, 8)
        this.dataList2 = res.data.vodata.splice(0, 8)
      },
      async getSpeakData() {
        const {data: res} = await getHotSpeak({
          page: 1,
          per_page: 14,
          orderType: 'createtime',
        })
        //console.log(res)
        if (res.code !== 200) return this.$message.error('请求失败')
        this.speakDataList = res.data.vodata.splice(0, 2)
        this.alterTitle = '暂无数据'
        this.speakDataList1 = res.data.vodata.splice(0, 2)
        this.speakDataList2 = res.data.vodata.splice(0, 2)
        this.speakDataList3 = res.data.vodata.splice(0, 2)
        this.speakDataList4 = res.data.vodata.splice(0, 2)
        this.speakDataList5 = res.data.vodata.splice(0, 2)
        this.speakDataList6 = res.data.vodata.splice(0, 2)
      },
      //软件更多点击事件
      softMore() {
        //this.$bus.$emit('cateId', this.cateId)
        this.$store.dispatch('setCateId', {
          cateId: this.cateId
        })
        this.$router.push('/cate')
      },
      //帖子更多点击事件
      speakMore() {
        this.$router.push('/speak')
      },
      //获取导航列表
      async getNavList() {
        const {data: res} = await getNavList({})
        if (res.code !== 200) return this.$message.error('请求失败')
        this.navList = res.data
        //console.log(res)
      },
      //navItem 点击事件
      navItem(index, id) {
        this.currentIndex = index
        this.cateId = id
        this.getData()
      },
    },
    components: {
      SpeakItem,ArticleFirstListItem
    }
  }
</script>

<style lang="less" scoped>
  .content {
    width: 80%;
    min-width: 1234px;
    margin: 0 auto;

    .top {
      display: flex;
      margin: 18px 0;
      justify-content: space-around;

      .top_one {
        height: 268px;
        width: 747px;

        .top_one_item {
          //overflow: ;
          height: 268px;
          width: 747px;

          img {
            height: 268px;
            width: 747px;
          }
        }
      }

      img {
        border-radius: 12px;
      }
    }

    .middle {
      width: 100%;

      .nav {
        height: 50px;
        line-height: 50px;
        border-radius: 8px;
        background-color: #ddd;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .bgw {
          background-color: #fff;
          font-weight: 700;
        }

        .item {
          text-align: center;
          width: 12%;
          cursor: pointer;
          font-size: 18px;
        }
      }

      .con {
        width: 100%;
        min-height: 200px;
        background-color: #fff;
        .more {
          padding: 8px 0;
          text-align: right;
          font-size: 14px;
          color: #797979;
          margin-right: 12px;
          span {
            cursor: pointer;
            &:hover {
              color: red;
            }
          }
        }

        .con_content {
          display: flex;
          //align-items: center;
          //justify-content: space-between;
          padding: 0 12px 30px 12px;

          flex-wrap: nowrap;
          width: 100%;
          /*height: 40%;*/

          .item {
            width: 12%;
            text-align: center;
            margin-right: 8px;
            margin-top: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
            height: max-content;

            .img {
              width: 110px;
              height: 100px;
              object-fit: cover;
            }

            span {
              margin-top: 10px;
            }
          }
        }

        .noneData {
          font-weight: 700;
          margin: 20px;
        }

      }
    }

    .footer {
      margin: 18px 0;
      padding-bottom: 10px;
      background-color: #fff;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .l {
          padding: 18px;
          font-size: 18px;
          font-weight: 700;
        }

        .r {
          font-size: 14px;
          color: #797979;
          cursor: pointer;
          margin-right: 12px;

          &:hover {
            color: red;
          }
        }
      }
      .con {
        display: flex;
        padding: 3px;
        //justify-content: space-around;
        text-align: left;
      }
    }
  }

</style>
