<template>
  <div class="ev_item" @click="goDetail">
    <div class="ev_item_title">{{title}}</div>
    <div class="ev_item_summary">{{summary}}</div>
    <p class="ev_item_time">{{createTime | dataFormat}}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props:{
    catePageIndex:{
      type:Number,
    },
    title:{
      type:String,
    },
    summary:{
      type:String,
    },
    createTime:{
      type:String
    },
    article_id:{
      type:Number,
      required:true
    }
  },
  methods: {
    goDetail() {
      this.$store.dispatch('catePageIndex', {
        catePageIndex: `${this.catePageIndex}`
      })
      this.$router.push(`/detailPost/${this.article_id}`)
    }
  },
  components: {}
}
</script>

<style lang="less" scoped>
.ev_item {
  padding: 6px;
  width:94%;
  height: 60px;
  margin-left:12px;
  &:hover {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .2);
  }
  .ev_item_title{
    height: 20px;
    display: flex;
    font-weight: 700;
    font-size: 18px;
  }
  .ev_item_summary{
    display: flex;
    margin-top: 10px;
    color: #797979;
    font-size: 14px;
  }
  .ev_item_time{
    display: flex;
    margin-top: -30px;
    justify-content: flex-end;
    font-size: 14px;
  }
}

</style>
